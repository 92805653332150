import './Intro.css'
import {Container, Col, Row } from 'react-bootstrap';
import intro_img from '../images/intro_img.png'
import { useNavigate } from 'react-router-dom';
import showreel from '../images/showreel.gif'
import useVisible from '../visibility/UseVisible';
// import wide_video from '../images/wide_video.mp4';
import wide_video from '../images/Ar12.mp4'
// import tall_video from '../images/tall_video.mp4';
import tall_vid from '../images/tall_vid.mp4';
import { useState, useEffect } from 'react';
import LogoMessageAR from '../images/LogoMessageAR.png'

export default function Intro(){
    const [ref,isVisible] = useVisible();
    const [mobile, setMobile] = useState(false);
     let navigate = useNavigate();
     
     const navigateToUploads = ()=>{
        navigate('/uploads')
     }

     useEffect(()=>{
        const handleResize = ()=>{
            setMobile(window.innerWidth<=768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup on unmount
          };
     },[])


    return(
        <>
        <div className='intro-section' ref={ref}>
        <Container fluid className='intro-container'>
            <Row style={{marginTop:'-50px'}}>
            <Col >
            
                <div className='main-logo'>
                    <img src={LogoMessageAR} alt="logo" />
                </div>
            </Col>
            </Row>
      <Row style={{marginTop:'-15px'}}>
            <Col xs={12} md={6}>
                <div className={`main-intro ${isVisible ? 'fade-in-on-load' : ''}`}>
                    {/* <h1>Bring Your Wishes to Life with Augmented Reality!</h1> */}
                    <h1>Bring Your Ideas to Life - because reality deserves a twist!</h1>

                    <p>Send personalized video greetings that appear right in front of your loved ones, in 
                    real-time and in their space!</p>

                    
                    <button className="sticky-btn" onClick={navigateToUploads}>Get Started</button>
                </div> 
            </Col>

            <Col xs={12} md={6}>
                <div className={`intro-image ${isVisible ? 'fade-in-on-load' : ''}`}>
                        {/* <img src={intro_img} alt="intro-img"  /> */}
                        {/* <img src={showreel} alt="intro-img"  /> */}
                        {mobile ? (
                             <video src={tall_vid} autoPlay muted loop playsInline></video>
                        ):(
                            <video src={wide_video} autoPlay muted loop playsInline></video>
                        )}
                </div>
            </Col>
                </Row>
                </Container>
                </div>
        </>
    )
}