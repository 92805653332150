import './Home.css'
import Intro from '../intro/Intro'
import Works from '../works/Works'
import Moment from '../moment/Moment'
import Occasion from '../occasion/Occasion'
import Testimonials from '../testimonials/Testimonials'
import Pricing from '../pricing/Pricing'
import Faq from '../faq/Faq'
import Footer from '../footer/Footer'
import ScrollToTopButton from '../scrollToTop/ScrollToTopButton'
import { useNavigate } from 'react-router-dom';
import CustomCursor from '../customCursor/CustomCursor'


export default function Home(){
    let navigate = useNavigate();

    const navigateToUploads = ()=>{
        navigate('/uploads')
     }

     const navigateToNewUploads = () =>{
        navigate('/new_upload')
     }
     

    return(
        <>

        <Intro/>
        <Works/>
        <Moment/>
        <Occasion/>
        <Testimonials/> 
        <Pricing/>
        <Faq/>  
        <Footer/>
        <ScrollToTopButton/>
        <button className="sticky-btn" onClick={navigateToUploads}>Get Started</button>
        {/* <button  onClick={navigateToNewUploads}>upload</button> */}

        <CustomCursor/>
        </>
    )
}