import './ContactModal.css';
import {Form, Modal, Button} from 'react-bootstrap'

export default function ContactModal({show, onHide, onSubmit, onChange, formData }){
    return(
        <>
         <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Connect with Us!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control 
                        type="text" 
                        name="firstName" 
                        value={formData.firstName} 
                        onChange={onChange} 
                        required />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control 
                        type="text" 
                        name="lastName" 
                        value={formData.lastName} 
                        onChange={onChange} 
                         />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                        type="email" 
                        name="email" 
                        value={formData.email} 
                        onChange={onChange} 
                        required />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control 
                        type="tel" 
                        name="phoneNumber"  
                        minLength="10"
                        maxLength="10"
                        pattern="\d*" 
                        value={formData.phoneNumber} 
                        onChange={onChange} required />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Message</Form.Label>
                        <Form.Control 
                        type="textarea" 
                        rows={4}
                        name="message" 
                        value={formData.message} 
                        onChange={onChange} 
                        />
                    </Form.Group>
                    <br/>
                    <Button variant="primary" type="submit">Submit</Button>
                </Form>
            </Modal.Body>
        </Modal>
        </>
    )
}