import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Faq.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown, faL } from '@fortawesome/free-solid-svg-icons';
import useVisible from '../visibility/UseVisible';


const faqData = [
    {
        id: 1,
        question: "How does messagear work?",
        answer: "MessageAR lets you record a video message and share it as an augmented reality (AR) experience, allowing recipients to view your message in their surroundings using their phone’s camera."
    },
    {
        id: 2,
        question: "Does MessageAR work on all devices?",
        answer: "MessageAR works on most modern smartphones with an updated web browser."
    },
    {
        id: 3,
        question: "How do I record and share an AR video?",
        answer: "Simply record your video using MessageAR, and we’ll generate a unique AR link. Share this link with your loved ones, and they can view your video in AR instantly—no app required!."
    },
    {
        id: 4,
        question: "What’s the video quality like?",
        answer: "All MessageAR plans support HD video. However, the final quality depends on the resolution of your recorded video. For the best results, record in good lighting with a high-quality camera."
    },
];

export default function Faq() {
    const [activeId, setActiveId] = useState(null);
    const [isToggled, setIsToggled] = useState(false);

    const[ref , isVisible] = useVisible();
    const[hasBecomeVisible, setHasBecomeVisible] = useState(false);

    useEffect(()=>{
        if(isVisible && !hasBecomeVisible){
            setHasBecomeVisible(true)
        }
    },[isVisible, hasBecomeVisible])

    const toggleFAQ = (id) => {
        setActiveId(activeId === id ? null : id); // Toggle functionality
        setIsToggled(!isToggled);
    };

   

    return (
        <>
     <div className='faq-main' ref={ref}>
        <Container className={`faq-container  ${hasBecomeVisible ? 'work-visible' : 'work-hidden'}`}>
            <Row>
                <Col>
                    <div className={`faq-heading ${isVisible ? 'slide-up':''}`} >
                        <h1>Frequently Asked Questions</h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{marginBottom:'50px'}}>
                    {faqData.map((faq,index) => (
                        <div key={faq.id} className={`faq-item ${isVisible ? 'slide-up':''}`} style={{animationDelay: `${index * 0.5}s`}} onClick={() => toggleFAQ(faq.id)}>
                            {/* <div className="faq-question" onClick={() => toggleFAQ(faq.id)}> */}
                            <div className="faq-question" >
                                {faq.question} <span><FontAwesomeIcon icon={isToggled ? faCaretUp : faCaretDown} /></span>
                            </div>
                            {activeId === faq.id && (
                                <div className="faq-answer">
                                    {faq.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
        </div>
        </>
    );
}














// import React from 'react';
// import { Col, Container, Row, Accordion } from 'react-bootstrap';
// import { useState } from 'react';
// import './Faq.css';

// const faqData = [
//     {
//         id: 1,
//         question: "How does the augmented reality feature work?",
//         answer: "When the recipient opens your shared link, they’ll see your video in their surroundings using their phone’s camera."
//     },
//     {
//         id: 2,
//         question: "Do I need any special equipment to remove the background from my video?",
//         answer: "Nope! Our advanced technology removes your background automatically—no green screen needed."
//     },
//     {
//         id: 3,
//         question: "How long is the link available to the receiver?",
//         answer: "In the free plan, links last for 7 days. With the premium plan, links are available for 30 days."
//     },
//     {
//         id: 4,
//         question: "What’s the video quality like?",
//         answer: "Our free plan offers basic quality, but with the premium plan, you get HD video for a sharper, more immersive experience."
//     }
// ];

// export default function Faq() {
//     const [activeKey, setActiveKey] = useState('1');  // Default to the first item being open

//     const handleSelect = (eventKey) => {
//         setActiveKey(eventKey === activeKey ? '' : eventKey);  // Toggle functionality
//     }

//     return (
//         <Container >
//             <Row>
//                 <Col>
//                     <div className='faq-heading'>
//                         <h1>Frequently Asked Questions</h1>
//                     </div>
//                 </Col>
//             </Row>
//             <Row >
//                 {faqData.map((item, index) => (
//                     // Create a new row for every two items when index is even
//                     index % 2 === 0 && (
//                         <Row key={item.id}>
//                             <Col md={6}>
//                                 <Accordion  activeKey={activeKey} onSelect={handleSelect}>
//                                     <Accordion.Item eventKey={item.id.toString()}>
//                                         <Accordion.Header>{item.question}</Accordion.Header>
//                                         <Accordion.Body>{item.answer}</Accordion.Body>
//                                     </Accordion.Item>
//                                 </Accordion>
//                             </Col>
//                             {/* Check if there is a next item */}
//                             {faqData[index + 1] && (
//                                 <Col md={6}>
//                                     <Accordion activeKey={activeKey} onSelect={handleSelect}>
//                                         <Accordion.Item eventKey={faqData[index + 1].id.toString()}>
//                                             <Accordion.Header>{faqData[index + 1].question}</Accordion.Header>
//                                             <Accordion.Body>{faqData[index + 1].answer}</Accordion.Body>
//                                         </Accordion.Item>
//                                     </Accordion>
//                                 </Col>
//                             )}
//                         </Row>
//                     )
//                 ))}
//             </Row>
//         </Container>
//     );
// }
