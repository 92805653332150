import './App.css';
import Uploaded_video from './components/uploadedVideos/Uploaded_video';
 import Home from './components/home/Home';
import { BrowserRouter, Router, Route, Routes } from 'react-router-dom'
import Video_recorder from './components/videoRecorder/Video_recorder';
import Video_uploader from './components/videoUploader/Video_uploader';
import PaymentForm from './components/payment/PaymentForm';


function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
      <Routes>

        <Route path='/' element={<Home/>}></Route>

        {/* <Route path='/uploads' element={<Uploaded_video/>}></Route> */}

        <Route path='/uploads' element={<Video_recorder/>}></Route>

        <Route path='/new_upload' element={<Video_uploader/>}></Route>
       {/* for removing background fron video go to route - localhost:3000/new_upload */}

       <Route path='/buy' element={<PaymentForm/>}></Route>
        
      </Routes>
      </BrowserRouter>
               
    </div>
  );
}

export default App;
