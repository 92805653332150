import { useState, useEffect } from "react"

export default function ScrollToTopButton(){
 const[isVisible, setIsVisible] = useState(false);

 useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

 const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };

    return(
        
        isVisible && (
            <button
              onClick={scrollToTop}
              style={{
                position: "fixed",
                bottom: "105px",
                right: "35px",
                backgroundColor: "#007BFF",
                color: "#fff",
                border: "none",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                fontSize: "20px",
                cursor: "pointer",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              ↑
            </button>
          )
        
        
    )
}