// import { useEffect, useState } from "react";
// import './Video_uploader.css';

// const Video_uploader = ({videoUrl})=> {
//     const [video, setVideo] = useState(null);
//     const [videoPreview, setVideoPreview] = useState(null);
//     const [url , setUrl] = useState('');

//     const handleVideoChange = (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             setVideo(file);
//             const reader = new FileReader();
//             reader.onloadend = () => {
//                 setVideoPreview(reader.result);
//             };
//             reader.readAsDataURL(file);
//         }
//     };

//     const handleUpload = async () => {
//         if (!video) {
//             alert("Please select a video to upload.");
//             return;
//         }
//         const formData = new FormData();
//         formData.append('video', video);

//         try {
//             const response = await fetch('http://localhost:4000/upload_video', { // Make sure the URL matches your Flask app
//                 method: 'POST',
//                 body: formData,
//             });

//             if (response.ok) {
//                 const videoBlob = await response.blob();
//                 const videoUrl = URL.createObjectURL(videoBlob);
//                 setVideoPreview(videoUrl);
//                 alert("Video processed successfully!");
//                 setVideo(null);
//             } else {
//                 alert('Failed to upload video.');
//             }
//         } catch (error) {
//             console.error('Error uploading video:', error);
//             alert('Error uploading video.');
//         }
//     };

// useEffect(()=>{
// if(!videoUrl){
//     fetch('ttp://127.0.0.1:6000/upload_video',{
//         method : 'GET',
//     })
//         .then(response => response.blob())
//         .then(blob => {
//             const url = URL.createObjectURL(blob);
//             setUrl(url);
//         })
//         .catch(error => console.error('Error fetching video:', error));
//     } else {
//       setUrl(videoUrl);
//     }
// },[videoUrl])

//     return (
//         <>
//         <div className="vid-upload">
//         <h2 >Upload Videos</h2>
//             <div className="video-uploader-main">
//                 <input type="file" accept="video/*" onChange={handleVideoChange} />
//                 {videoPreview && (
//                     <div>
//                         <video src={videoPreview} className="video-preview" controls />
//                     </div>
//                 )}
//                 <button onClick={handleUpload} className="upload_button">Upload and Process Video</button>
//             </div>
//         </div>

//         </>
//     );
// }

// export default Video_uploader;

// // progress bar
// import './Video_uploader.css';
// import { useEffect, useState } from "react";

// const Video_uploader = ({ videoUrl }) => {
//     const [video, setVideo] = useState(null);
//     const [videoPreview, setVideoPreview] = useState(null);
//     const [url, setUrl] = useState('');
//     const [conversionProgress, setConversionProgress] = useState(0); // For conversion progress
//     const [loading, setLoading] = useState(false); // State to show/hide preloader
//     const [uploading, setUploading] = useState(false); // To track file upload status

//     // Handle video file selection and preview generation
//     const handleVideoChange = (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             setVideo(file);
//             const reader = new FileReader();
//             reader.onloadend = () => {
//                 setVideoPreview(reader.result);
//             };
//             reader.readAsDataURL(file);
//         }
//     };

//     // Handle video upload and processing
//     const handleUpload = async () => {
//         if (!video) {
//             alert("Please select a video to upload.");
//             return;
//         }

//         setLoading(true); // Show preloader while uploading
//         setUploading(true); // Track file upload status

//         const formData = new FormData();
//         formData.append('video', video);

//         try {
//             const response = await fetch('http://localhost:4000/upload_video', {
//                 method: 'POST',
//                 body: formData,
//             });

//             if (response.ok) {
//                 const videoBlob = await response.blob();
//                 const videoUrl = URL.createObjectURL(videoBlob);
//                 setVideoPreview(videoUrl);
//                 alert("Video processed successfully!");
//                 setVideo(null);
//             } else {
//                 alert('Failed to upload video.');
//             }
//         } catch (error) {
//             console.error('Error uploading video:', error);
//             alert('Error uploading video.');
//         } finally {
//             setLoading(false); // Hide preloader after upload/process
//             setUploading(false); // Stop tracking upload status
//         }
//     };

//     // Poll the server for conversion progress (used if there's background processing)
//     useEffect(() => {
//         const interval = setInterval(() => {
//             if (uploading || loading) { // Only poll progress if uploading/processing
//                 fetch('http://localhost:4000/get_progress')
//                     .then(response => response.json())
//                     .then(data => {
//                         setConversionProgress(data.progress);
//                         if (data.progress >= 100) {
//                             clearInterval(interval); // Stop polling when conversion is complete
//                         }
//                     })
//                     .catch(error => console.error('Error fetching progress:', error));
//             }
//         }, 1000); // Poll every 1 second

//         return () => clearInterval(interval); // Cleanup on unmount
//     }, [uploading, loading]); // Dependency to re-poll only if uploading/processing

//     // Fetch the video URL if it exists in the backend
//     useEffect(() => {
//         if (!videoUrl) {
//             fetch('http://localhost:4000/upload_video', {
//                 method: 'GET',
//             })
//                 .then(response => response.blob())
//                 .then(blob => {
//                     const url = URL.createObjectURL(blob);
//                     setUrl(url);
//                 })
//                 .catch(error => console.error('Error fetching video:', error));
//         } else {
//             setUrl(videoUrl);
//         }
//     }, [videoUrl]);

//     return (
//         <>
//             <div className="vid-upload">
//                 {/* <h2>Upload Videos</h2> */}
//                 <div className="video-uploader-main">
//                     <input type="file" accept="video/*" onChange={handleVideoChange} style={{color:'white'}}/>

//                     {/* Preloader: Shows when uploading or processing */}
//                     {loading && (
//                         <div className="preloader">
//                             <div className="spinner"></div>
//                             <p>Conversion: {conversionProgress}%</p>
//                         </div>
//                     )}

//                     {/* Show video preview if available */}
//                     {videoPreview && !loading && (
//                         <div>
//                             <video src={videoPreview} className="video-preview" controls />
//                         </div>
//                     )}

//                     <button onClick={handleUpload} className="upload_button">Upload and Process Video</button>

//                     {/* Progress Bar for Conversion */}
//                     {conversionProgress > 0 && (
//                         <div className="progress-bar">
//                             <div className="progress" style={{ width: `${conversionProgress}%` }}>
//                                 Conversion: {conversionProgress}%
//                             </div>
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </>
//     );
// };

// export default Video_uploader;

// correct version till now

import { useEffect, useState } from "react";
import "./Video_uploader.css";
import { Container, Col } from "react-bootstrap";

const Video_uploader = ({ videoUrl }) => {
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [url, setUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setVideo(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setVideoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    if (!video) {
      alert("Please select a video to upload.");
      return;
    }
    setIsUploading(true);
    const formData = new FormData();
    formData.append("video", video);

    try {
      const response = await fetch("http://localhost:4000/upload_video", {
        // Make sure the URL matches your Flask app
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const videoBlob = await response.blob();
        const videoUrl = URL.createObjectURL(videoBlob);
        setVideoPreview(videoUrl);
        alert("Video processed successfully!");
        setVideo(null);
      } else {
        alert("Failed to upload video.");
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      alert("Error uploading video.");
    }
    setIsUploading(false);
  };

  useEffect(() => {
    if (!videoUrl) {
      fetch("ttp://127.0.0.1:4000/upload_video", {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          setUrl(url);
        })
        .catch((error) => console.error("Error fetching video:", error));
    } else {
      setUrl(videoUrl);
    }
  }, [videoUrl]);

  return (
    <>
    <Container>
      <div className="vid-upload">
        <h2 className="text-center">Upload Videos</h2>
        <div className="video-uploader-main">
            <Col>
          <div className="vid-preview">
            <input type="file" accept="video/*" onChange={handleVideoChange} />
            {videoPreview && (
              <div>
                <video src={videoPreview} className="video-preview" controls />
              </div>
            )}
          </div>
          </Col>
          <button onClick={handleUpload} className="upload_button">
          {isUploading ? "Uploading..." : "Upload and Process Video"}
          </button>
        </div>
      </div>
      </Container>
    </>
  );
};

export default Video_uploader;
