// import { useEffect, useState, useRef } from 'react';

// export default function useVisible() {
//   const [isVisible, setIsVisible] = useState(false);
//   const [isScrollingDown, setIsScrollingDown] = useState(false);
//   const lastScrollTop = useRef(0); // Stores the last scroll position
//   const hasBecomeVisible = useRef(false);
//   const ref = useRef();

//   useEffect(() => {
//     const handleScroll = () => {
//       const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

//       if (currentScrollTop > lastScrollTop.current) {
//         setIsScrollingDown(true);
//       } else {
//         setIsScrollingDown(false);
//       }
//       lastScrollTop.current = currentScrollTop;
//     };

//     window.addEventListener('scroll', handleScroll);

//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         setIsVisible(entry.isIntersecting);
//         hasBecomeVisible.current = true;
//       },
//       { threshold: 0.5 } // Trigger when 50% of the element is visible
//     );

//     if (ref.current) {
//       observer.observe(ref.current);
//     }

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//       if (ref.current) {
//         observer.unobserve(ref.current);
//       }
//     };
//   }, []);

//   return [ref, isVisible && isScrollingDown];
// }














import { useEffect, useState, useRef } from 'react';

export default function useVisible() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  const hasBeenVisible = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasBeenVisible.current) {
            setIsVisible(true);
            hasBeenVisible.current = true; // Mark as visible once it intersects
          }
      },
      { threshold: 0.2 } // Trigger when 50% of the element is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return [ref, isVisible];
}


