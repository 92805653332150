import React, { useState, useEffect } from "react";
import "./CustomCursor.css"; // Import the CSS for styling
const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hidden, setHidden] = useState(false);
  const [delayedPosition, setDelayedPosition] = useState({ x: 0, y: 0 });
  const [cursorStyle, setCursorStyle] = useState("default");
  // This function updates the cursor position immediately
  const updatePosition = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
    if (e.target.tagName === "BUTTON") {
      setCursorStyle("click"); // Change to click style when over a button
    } else {
      setCursorStyle("default"); // Default style otherwise
    }
  };
  useEffect(() => {
    const handleMouseMove = (e) => {
      updatePosition(e);
      setHidden(false);
    };
    // Attach mouse move listener to the document
    document.addEventListener("mousemove", handleMouseMove);
    // Remove event listener on cleanup
    return () => document.removeEventListener("mousemove", handleMouseMove);
  }, []);
  useEffect(() => {
    // Delayed update to position
    const timer = setTimeout(() => {
      setDelayedPosition(position);
    }, 10); // 1000 ms delay
    return () => clearTimeout(timer);
  }, [position]);
  return (
    <div
      className={`custom-cursor ${cursorStyle === "click" ? "click" : ""}`}
      style={{
        left: `${delayedPosition.x}px`,
        top: `${delayedPosition.y}px`,
        opacity: hidden ? 0 : 1,
      }}
    >
      {cursorStyle === "click" ? "Click" : ""}
    </div>
  );
};
export default CustomCursor;
