// import './ConfirmModal.css'

// export default function ConfirmModal({ isOpen, onConfirm, onCancel, message }){
//     if (!isOpen) return null;

//     return(
//         <>
//             <div className="modal-backdrop">
//             <div className="modal-content">
//                 <h2>Discard Video?</h2>
//                 <p >Are you sure you want to leave?</p>
//                 <button onClick={onConfirm} className='discard'>Discard</button>
//                 <button onClick={onCancel} className='keep'>Keep</button>

//             </div>
//             </div>
//         </>
//     )
// }


import './ConfirmModal.css'

export default function ConfirmModal({ isOpen, onConfirm, onCancel, message, onRestart }){
    if (!isOpen) return null;

    return(
        <>
            <div className="modal-backdrop">
            <div className="modal-content">
                <h2>{message.title}</h2>
                <p >{message.body}</p>
                <button onClick={onConfirm} className='discard'>Discard</button>
                <button onClick={onCancel} className='keep'>Keep</button>
                <button onClick={onRestart} className='restart'>Start Over</button>

            </div>
            </div>
        </>
    )
}