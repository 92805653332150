//  // correct code(6th feb) - captcha added + registration form(frontend) added + normal video processed + trying to store form data & video processed

import { useRef, useState, useEffect } from "react";
import "./Video_recorder.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faCamera,
  faCameraRotate,
  faSync,
  faDownload,
  faArrowLeft,
  faXmark,
  faChampagneGlasses,
} from "@fortawesome/free-solid-svg-icons";
import { faSquare, faCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../confirmModal/ConfirmModal";
import RegistrationModal from "../registrationModal/RegistrationModal";
import ReCAPTCHA from "react-google-recaptcha";

export default function Video_recorder() {
  const videoRef = useRef(null);
  // const [camera, setCamera] = useState("user");    //default front camera
  const [camera, setCamera] = useState("environment"); //default back camera
  const [videoUpload, setVideoUpload] = useState(null);
  const [processedVideoUrl, setProcessedVideoUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [myVideo, setMyVideo] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const mediaRecorder = useRef(null);
  const videoChunks = useRef([]);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [registrationData, setRegistrationData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    video: null,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [captchaVisible, setCaptchaVisible] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [videoMessage, setVideoMessage] = useState(false);

  useEffect(() => {
    if (!captchaVisible) {
      setVideoMessage(true);
        const timer = setTimeout(() => {
          setVideoMessage(false);
        }, 2000); // Message will be visible for 3000 milliseconds (3 seconds)
        return () => clearTimeout(timer);
    }
}, [captchaVisible]);
  
  let navigate = useNavigate();

  const handleBackClick = () => {
    let message = {
      title: "",
      body: "",
    };
    if (isRecording) {
      message.title = "Recording in Progress";
      message.body =
        "Recording is in progress. Do you want to keep or discard or start over the recording?";
    } else if (videoUpload && !processedVideoUrl) {
      message.title = "Recording Complete";
      message.body =
        "Recording complete. Do you want to keep this recording or discard or start over?";
    } else if (processedVideoUrl) {
      message.title = "Video Processed";
      message.body =
        "Do you want to keep the converted video or discard or start over?";
    } else {
      navigate("/"); 
      return;
    }
    setModalMessage(message);
    setShowConfirmModal(true);
  };

  const handleConfirmLeave = () => {
    if (isRecording || videoUpload) {
      if (
        mediaRecorder.current &&
        mediaRecorder.current.state === "recording"
      ) {
        mediaRecorder.current.stop();
      }
      setIsRecording(false);
      setVideoUpload(null);
      setProcessedVideoUrl(null);
    }
    navigate("/");
    setShowConfirmModal(false);
  };

  const handleCancelLeave = () => {
    setShowConfirmModal(false);
  };

  const handleRestartRecording = () => {
    console.log("restart function enter");
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
      console.log("mediaRecorder :", mediaRecorder);
    }
    setIsRecording(false);
    console.log("recording discarded");
    setVideoUpload(null);
    console.log("video upload discarded");
    setProcessedVideoUrl(null);
    console.log("processed video discarded");
    setShowConfirmModal(false);
    console.log("modal shown");
    // startRecording();
    initializeCamera();
    console.log("new recording started");
  };

  const initializeCamera = async () => {
    try {
      const stream = await getMediaStream();
      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };

  useEffect(() => {
    initializeCamera();
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [camera]);

  useEffect(() => {
    const matchMedia = window.matchMedia("(min-width: 768px)");
    const handleChange = (e) => {
      // Automatically switch to front camera on big screens
      setCamera(e.matches ? "user" : camera);
      if (videoRef.current && videoRef.current.srcObject) {
        stopMediaStream(videoRef.current.srcObject);
        startRecording();
      }
    };
    matchMedia.addEventListener("change", handleChange);
    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
  }, [camera]);

  const getMediaStream = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("getUserMedia is not supported in this browser.");
      alert(
        "Error accessing the camera or microphone. Please ensure you have granted the necessary permissions."
      );
      throw new Error("getUserMedia not supported");
    }
    const constraints = {
      video: { facingMode: camera },
      // audio: true,
      audio: {
        echoCancellation: true, // Enable echo cancellation
        noiseSuppression: true, // Enable noise suppression
        autoGainControl: false,
        sampleRate: 44100, // Standard sample rate for good quality
        channelCount: 1,
      },
    };
    return await navigator.mediaDevices.getUserMedia(constraints);
  };

  const toggleCamera = async () => {
    if (isRecording) {
      stopRecording();
    }

    const newCamera = camera === "user" ? "environment" : "user";
    setCamera(newCamera);

    if (isRecording) {
      await new Promise((resolve) => setTimeout(resolve, 500)); // Optional: Add delay to ensure media stream is properly initialized
      startRecording();
    }
    console.log("camera :", camera);
  };

  const startRecording = () => {
    setIsRecording(true);
    const stream = videoRef.current.srcObject;
    if (!stream) {
      console.error("No media stream available for recording");
      // alert('No media stream available for recording');
      setIsRecording(false);
      return;
    }
    mediaRecorder.current = new MediaRecorder(stream);
    videoChunks.current = []; // Clear previous data chunks
    mediaRecorder.current.ondataavailable = (e) => {
      videoChunks.current.push(e.data);
    };
    mediaRecorder.current.start();
    console.log("Started recording");
  };

  const stopRecording = () => {
    console.log("stop block");
    console.log(mediaRecorder.current);
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      console.log("stop function working");
      mediaRecorder.current.stop();
      console.log("stop function");
      mediaRecorder.current.onstop = () => {
        const mimeType = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
        const videoBlob = new Blob(videoChunks.current, {
          type: MediaRecorder.isTypeSupported(mimeType)
            ? mimeType
            : "video/webm",
        });
        setVideoUpload(videoBlob);
        const videoUrl = URL.createObjectURL(videoBlob);
        videoRef.current.srcObject = null;
        videoRef.current.src = videoUrl;
        videoChunks.current = [];
        setIsRecording(false);
        console.log("stopped recording");
        setShowRegistrationForm(true);
      };
      stopMediaStream(videoRef.current.srcObject);
    } else {
      console.error(
        "Attempted to stop recording when mediaRecorder is not active or not initialized."
      );
    }
  };

  const stopMediaStream = (stream, callback) => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      console.log("All tracks stopped.");
      if (callback) {
        callback();
      }
    }
  };

  // video is getting uploaded in s3 bucket and in local folder
  const uploadVideo = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("video", videoUpload, "upload_video.mp4");

    try {
      // background - removal api
      // const response = await fetch("http://localhost:4000/upload_video", {
      // normal video uploading
      const response = await fetch(
        "http://localhost:4000/upload_normal_video",
        {
          method: "POST",
          body: formData,
        }
      );
      // const data = await response.blob();
      const data = await response.json();
      if (!response.ok) throw new Error("Server responded with an error");
      // const videoUrl = URL.createObjectURL(data);
      const videoUrl = data.video_url;
      setProcessedVideoUrl(videoUrl);
      videoRef.current.src = videoUrl; // Update the video source to show the processed video
      // alert('Video uploaded successfully!');
      setShowMessage(true);
      return videoUrl;
    } catch (error) {
      console.error("Error uploading video:", error);
      alert("Failed to upload video.");
      return null;
    } finally {
      setLoading(false);
    }
  };

  console.log("processedVideoUrl", processedVideoUrl);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(processedVideoUrl)
      .then(() => console("Link copied to clipboard!"))
      .catch((err) => console.error("Could not copy text: ", err));
  };

  const handleRegistrationChange = (e) => {
    setRegistrationData({
      ...registrationData,
      [e.target.name]: e.target.value,
    });
  };

  // const handleNext = () => {
  //   if (!formSubmitted) {
  //     setShowRegistrationForm(true);
  //   } else {
  //     // uploadVideo(); // Proceed if the form is already submitted
  //     console.log("videoooooooooo");
  //   }
  // };

  // captcha handling
  const onCaptchaChange = async (value) => {
    console.log("Captcha value:", value);
    if (!value) {
      console.error(
        "Failed to load reCAPTCHA. Please check your connection and try again."
      );
      return;
    }

    try {
      // const response = await fetch('http://localhost:3001/api/verify_captcha', {
      const response = await fetch(`http://localhost:4000/verify_captcha`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: value }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("data:", data);

      if (data.success) {
        setCaptchaVisible(false); // Hide CAPTCHA only if verified successfully
      } else {
        console.error("CAPTCHA verification failed:", data.message);
      }
    } catch (error) {
      console.error("Error during CAPTCHA verification:", error.message);
    }
  };

  // // registartion form data is getting submitted(feb25)
  const handleRegistrationSubmit = async (e) => {
    e.preventDefault();
    console.log("Registration Data:", registrationData);

    try {
      console.log("entered try block");

      // First, upload the video to get the presigned URL
      const videoUrl = await uploadVideo();
      console.log("videoUrl", videoUrl);

      if (!videoUrl) {
        console.error("Video upload failed. Registration aborted.");
        return;
      }

      // Append the videoUrl to your registration data
      const updatedRegistrationData = {
        ...registrationData,
        video_url: videoUrl,
      };

      console.log("updatedRegistrationData", updatedRegistrationData);

      const response = await fetch("http://localhost:4000/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedRegistrationData),
      });

      console.log('response', response);
      

      if (response.ok) {
        console.log("Registration successful");
        setShowRegistrationForm(false);
        setFormSubmitted(true);
      } else {
        const errorData = await response.json();
        console.error("Registration failed:", errorData.message);
      }
    } catch (error) {
      console.error("Network error:", error.message);
    }
  };

  return (
    <>
      {captchaVisible && (
        <div className="recaptcha-container">
          <ReCAPTCHA
            // //localhost
            // sitekey="6LdJNcsqAAAAAG72GWyucAZjunpIfiVwGfb1Jz6c"
            // onChange={onCaptchaChange}

            // // messageAr domain
            sitekey="6LdX3tUqAAAAANng35SXTP2eoegqK-fFH_6IxeWf"
            onChange={onCaptchaChange}
          />
        </div>
      )}

      <div className="rec">
        {/* <h2>Create a special message!</h2> */}

        {myVideo ? (
          <video width="600" controls>
            <source src={myVideo} type="video/mp4" />
          </video>
        ) : (
          <video
            ref={videoRef}
            //controls
            autoPlay
            playsInline
            loop
            style={{ width: "100%" }}
            className="video-mirrored"
          ></video>
        )}

        <div className="rec_btn">
          <ConfirmModal
            isOpen={showConfirmModal}
            onConfirm={handleConfirmLeave}
            onCancel={handleCancelLeave}
            message={modalMessage}
            onRestart={handleRestartRecording}
          />

          <RegistrationModal
            show={showRegistrationForm}
            onHide={() => setShowRegistrationForm(false)}
            onSubmit={handleRegistrationSubmit}
            onChange={handleRegistrationChange}
            formData={registrationData}
          />

          <button onClick={handleBackClick} className="back-btn">
            Back
          </button>

          {!isRecording ? (
            <button onClick={startRecording} className="recording-btn">
              <FontAwesomeIcon icon={faCircle} />
            </button>
          ) : (
            <button
              onClick={stopRecording}
              className="recording-btn active-btn"
            >
              <FontAwesomeIcon icon={faSquare} />
            </button>
          )}

          {/* <button
            onClick={handleNext}
            disabled={loading}
            className="upload-btn"
          >
            {loading ? "Uploading..." : "Next"}
          </button> */}

          {/* <button onClick={toggleCamera}  className="toggle-btn">{camera === "user" ? "Back Camera" : "front Camera"}</button> */}
          <button onClick={toggleCamera} className="toggle-btn">
            {camera === "user" ? (
              <FontAwesomeIcon icon={faCamera} />
            ) : (
              <FontAwesomeIcon icon={faCameraRotate} />
            )}
          </button>

          {/* code for download button and copy button  */}

          {processedVideoUrl && (
            <div className="video-link">
              <button onClick={copyToClipboard} className="copy-btn">
                <FontAwesomeIcon icon={faCopy} />{" "}
              </button>
              <button className="download-btn">
                <a
                  href={processedVideoUrl}
                  download={`processed_video.mp4`}
                  className="download-btn"
                >
                  <FontAwesomeIcon icon={faDownload} />
                </a>
              </button>
            </div>
          )}

          {videoMessage && (
            <div className="video-msg">
              <p >Please take full body video</p>
            </div>
          )}

          {showMessage && (
            <div
              className="alert alert-success"
              style={{
                position: "absolute",
                top: "40%",
                width: "350px",
                padding: "30px",
                backgroundColor: "white",
              }}
            >
              Your video has been processed! You will receive your video soon
              with AR effects.
              <button
                onClick={() => {
                  setShowMessage(false);
                  navigate("/");
                }}
                className="close-btn"
                style={{
                  float: "right",
                  cursor: "pointer",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  backgroundColor: "red",
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

















//  // correct code(6th feb) - captcha added + registration form(frontend) added + normal video processed + s3 integrated

// import { useRef, useState, useEffect } from "react";
// import "./Video_recorder.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {faCopy, faCamera, faSync, faDownload, faArrowLeft, faXmark} from "@fortawesome/free-solid-svg-icons";
// import { faSquare, faCircle } from "@fortawesome/free-solid-svg-icons";
// import { useNavigate } from "react-router-dom";
// import ConfirmModal from "../confirmModal/ConfirmModal";
// import RegistrationModal from "../registrationModal/RegistrationModal";
// import ReCAPTCHA from "react-google-recaptcha";

// export default function Video_recorder() {
//   const videoRef = useRef(null);
//   const [camera, setCamera] = useState("user");
//   const [videoUpload, setVideoUpload] = useState(null);
//   const [processedVideoUrl, setProcessedVideoUrl] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [myVideo, setMyVideo] = useState(null);
//   const [isRecording, setIsRecording] = useState(false);
//   const [showConfirmModal, setShowConfirmModal] = useState(false);
//   const [modalMessage, setModalMessage] = useState("");
//   const mediaRecorder = useRef(null);
//   const videoChunks = useRef([]);
//   // registration details
//   const [showRegistrationForm, setShowRegistrationForm] = useState(false);
//   const [registrationData, setRegistrationData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     phoneNumber: "",
//   });
//   const [formSubmitted, setFormSubmitted] = useState(false);
//   const [captchaVisible, setCaptchaVisible] = useState(true);
//   const [showMessage , setShowMessage] = useState(false);
//   console.log('showMessage:',showMessage)

//   let navigate = useNavigate();

//   const handleBackClick = () => {
//     let message = {
//       title: "",
//       body: "",
//     };
//     if (isRecording) {
//       message.title = "Recording in Progress";
//       message.body =
//         "Recording is in progress. Do you want to keep or discard or start over the recording?";
//     } else if (videoUpload && !processedVideoUrl) {
//       message.title = "Recording Complete";
//       message.body =
//         "Recording complete. Do you want to keep this recording or discard or start over?";
//     } else if (processedVideoUrl) {
//       message.title = "Video Processed";
//       message.body =
//         "Do you want to keep the converted video or discard or start over?";
//     } else {
//       navigate("/"); // No modal needed if none of the above conditions are met
//       return;
//     }
//     setModalMessage(message);
//     setShowConfirmModal(true);
//   };

//   const handleConfirmLeave = () => {
//     if (isRecording || videoUpload) {
//       if (
//         mediaRecorder.current &&
//         mediaRecorder.current.state === "recording"
//       ) {
//         mediaRecorder.current.stop();
//       }
//       setIsRecording(false);
//       setVideoUpload(null);
//       setProcessedVideoUrl(null);
//     }
//     navigate("/");
//     setShowConfirmModal(false);
//   };

//   const handleCancelLeave = () => {
//     setShowConfirmModal(false);
//   };

//   const handleRestartRecording = () => {
//     console.log("restart function enter");
//     if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
//       mediaRecorder.current.stop();
//       console.log("mediaRecorder :", mediaRecorder);
//     }
//     setIsRecording(false);
//     console.log("recording discarded");
//     setVideoUpload(null);
//     console.log("video upload discarded");
//     setProcessedVideoUrl(null);
//     console.log("processed video discarded");
//     setShowConfirmModal(false);
//     console.log("modal shown");
//     // startRecording();
//     initializeCamera();
//     console.log("new recording started");
//   };

//   const initializeCamera = async () => {
//     try {
//       const stream = await getMediaStream();
//       videoRef.current.srcObject = stream;
//     } catch (error) {
//       console.error("Error accessing the camera:", error);
//     }
//   };

//   useEffect(() => {
//     initializeCamera();
//     return () => {
//       if (videoRef.current && videoRef.current.srcObject) {
//         const tracks = videoRef.current.srcObject.getTracks();
//         tracks.forEach((track) => track.stop());
//       }
//     };
//   }, [camera]);

//   useEffect(() => {
//     const matchMedia = window.matchMedia("(min-width: 768px)");
//     const handleChange = (e) => {
//       // Automatically switch to front camera on big screens
//       setCamera(e.matches ? "user" : camera);
//       if (videoRef.current && videoRef.current.srcObject) {
//         stopMediaStream(videoRef.current.srcObject);
//         startRecording();
//       }
//     };
//     matchMedia.addEventListener("change", handleChange);
//     return () => {
//       matchMedia.removeEventListener("change", handleChange);
//     };
//   }, [camera]);

//   const getMediaStream = async () => {
//     if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
//       console.error("getUserMedia is not supported in this browser.");
//       alert(
//         "Error accessing the camera or microphone. Please ensure you have granted the necessary permissions."
//       );
//       throw new Error("getUserMedia not supported");
//     }
//     const constraints = {
//       video: { facingMode: camera },
//       // audio: true,
//       audio: {
//         echoCancellation: true, // Enable echo cancellation
//         noiseSuppression: true, // Enable noise suppression
//         autoGainControl: false,
//         sampleRate: 44100, // Standard sample rate for good quality
//         channelCount: 1,
//       },
//     };
//     return await navigator.mediaDevices.getUserMedia(constraints);
//   };

//   const toggleCamera = async () => {
//     if (isRecording) {
//       stopRecording();
//     }

//     const newCamera = camera === "user" ? "environment" : "user";
//     setCamera(newCamera);

//     if (isRecording) {
//       await new Promise((resolve) => setTimeout(resolve, 500)); // Optional: Add delay to ensure media stream is properly initialized
//       startRecording();
//     }
//     console.log("camera :", camera);
//   };

//   const startRecording = () => {
//     setIsRecording(true);
//     const stream = videoRef.current.srcObject;
//     if (!stream) {
//       console.error("No media stream available for recording");
//       // alert('No media stream available for recording');
//       setIsRecording(false);
//       return;
//     }
//     mediaRecorder.current = new MediaRecorder(stream);
//     videoChunks.current = []; // Clear previous data chunks
//     mediaRecorder.current.ondataavailable = (e) => {
//       videoChunks.current.push(e.data);
//     };
//     mediaRecorder.current.start();
//     console.log("Started recording");
//   };

//   const stopRecording = () => {
//     console.log("stop block");
//     console.log(mediaRecorder.current);
//     if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
//       console.log("stop function working");
//       mediaRecorder.current.stop();
//       console.log("stop function");
//       mediaRecorder.current.onstop = () => {
//         const mimeType = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
//         const videoBlob = new Blob(videoChunks.current, {
//           type: MediaRecorder.isTypeSupported(mimeType)
//             ? mimeType
//             : "video/webm",
//         });
//         setVideoUpload(videoBlob);
//         const videoUrl = URL.createObjectURL(videoBlob);
//         videoRef.current.srcObject = null;
//         videoRef.current.src = videoUrl;
//         videoChunks.current = [];
//         setIsRecording(false);
//         console.log("stopped recording");
//         setShowRegistrationForm(true);
//       };
//       stopMediaStream(videoRef.current.srcObject);
//     } else {
//       console.error(
//         "Attempted to stop recording when mediaRecorder is not active or not initialized."
//       );
//     }
//   };

//   const stopMediaStream = (stream, callback) => {
//     if (stream) {
//       const tracks = stream.getTracks();
//       tracks.forEach((track) => track.stop());
//       console.log("All tracks stopped.");
//       if (callback) {
//         callback();
//       }
//     }
//   };

//   const uploadVideo = async () => {
//     setLoading(true);

//     const formData = new FormData();
//     formData.append("video", videoUpload, "upload_video.mp4");

//     try {
//       // background - removal api
//       // const response = await fetch("http://localhost:4000/upload_video", {
//       // normal video uploading
//       const response = await fetch('http://localhost:4000/upload_normal_video',{
//         method: "POST",
//         body: formData,
//       });
//       const data = await response.blob();
//       if (!response.ok) throw new Error("Server responded with an error");
//       const videoUrl = URL.createObjectURL(data);
//       setProcessedVideoUrl(videoUrl);
//       videoRef.current.src = videoUrl; // Update the video source to show the processed video
//       // alert('Video uploaded successfully!');
//       setShowMessage(true);
//     } catch (error) {
//       console.error("Error uploading video:", error);
//       alert("Failed to upload video.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   console.log("processedVideoUrl", processedVideoUrl);

//   const copyToClipboard = () => {
//     navigator.clipboard
//       .writeText(processedVideoUrl)
//       .then(() => console("Link copied to clipboard!"))
//       .catch((err) => console.error("Could not copy text: ", err));
//   };

//   const handleRegistrationChange = (e) => {
//     setRegistrationData({
//       ...registrationData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleNext = () => {
//     if (!formSubmitted) {
//       setShowRegistrationForm(true); // Show the form if not yet submitted
//     } else {
//       uploadVideo(); // Proceed if the form is already submitted
//     }
//   };

//   const onCaptchaChange = async (value) => {
//     console.log("Captcha value:", value);
//     if (!value) {
//       console.error(
//         "Failed to load reCAPTCHA. Please check your connection and try again."
//       );
//       return;
//     }

//     try {
//       // const response = await fetch('http://localhost:3001/api/verify_captcha', {
//       const response = await fetch(`http://localhost:4000/verify_captcha`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ token: value }),
//       });

//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       const data = await response.json();
//       console.log("data:", data);

//       if (data.success) {
//         setCaptchaVisible(false); // Hide CAPTCHA only if verified successfully
//       } else {
//         console.error("CAPTCHA verification failed:", data.message);
//       }
//     } catch (error) {
//       console.error("Error during CAPTCHA verification:", error.message);
//     }
//   };

//   const handleRegistrationSubmit = async(e) => {
//     e.preventDefault();
//     console.log("Registration Data:", registrationData);

//     try{
//       const response = await fetch(`http://localhost:4000/register`,{
//         method:"POST",
//         headers:{
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(registrationData)
//       })

//       if (response.ok) { // Check if the response is ok (status in the range 200-299)
//         console.log("Registration successful");
//         setShowRegistrationForm(false);
//         setFormSubmitted(true);
//         uploadVideo(); // Assume you move to video upload or processing after registration
//     } else {
//         const errorData = await response.json(); // Get the error message from the response if any
//         console.error("Registration failed:", errorData.message);
//     }
//     }catch(error){
//       console.error("Network error:", error.message);
//     }
//     // Process registration data here
//     // setShowRegistrationForm(false);
//     // setFormSubmitted(true);
//     // uploadVideo(); // Assume you move to video upload or processing after registration
//   };

//   return (
//     <>
//       {captchaVisible && (
//         <div className="recaptcha-container">
//           <ReCAPTCHA
//           // localhost
//             sitekey="6LdJNcsqAAAAAG72GWyucAZjunpIfiVwGfb1Jz6c"

//             // messageAr domain
//             //  sitekey="6LdX3tUqAAAAANng35SXTP2eoegqK-fFH_6IxeWf"
//             onChange={onCaptchaChange}
//           />
//         </div>
//       )}

//       <div className="rec">
//         {/* <h2>Create a special message!</h2> */}

//         {myVideo ? (
//           <video width="600" controls>
//             <source src={myVideo} type="video/mp4" />
//           </video>
//         ) : (
//           <video
//             ref={videoRef}
//             //controls
//             autoPlay
//             playsInline
//             loop
//             style={{ width: "100%" }}
//             className="video-mirrored"
//           ></video>
//         )}

//         <div className="rec_btn">
//           <ConfirmModal
//             isOpen={showConfirmModal}
//             onConfirm={handleConfirmLeave}
//             onCancel={handleCancelLeave}
//             message={modalMessage}
//             onRestart={handleRestartRecording}
//           />

//           <RegistrationModal
//             show={showRegistrationForm}
//             onHide={() => setShowRegistrationForm(false)}
//             onSubmit={handleRegistrationSubmit}
//             onChange={handleRegistrationChange}
//             formData={registrationData}
//           />

//           <button onClick={handleBackClick} className="back-btn">
//             Back
//           </button>

//           {!isRecording ? (
//             <button onClick={startRecording} className="recording-btn">
//               <FontAwesomeIcon icon={faCircle} />
//             </button>
//           ) : (
//             <button
//               onClick={stopRecording}
//               className="recording-btn active-btn"
//             >
//               <FontAwesomeIcon icon={faSquare} />
//             </button>
//           )}

//           {/* {!processedVideoUrl  && videoUpload && (
//                          <button onClick={uploadVideo} disabled={loading} className="upload-btn">
//                              {loading ? "Uploading..." : "Next"}
//                          </button>
//               )}   */}

//           <button
//             onClick={handleNext}
//             disabled={loading}
//             className="upload-btn"
//           >
//             {loading ? "Uploading..." : "Next"}
//           </button>

//           {/* <button onClick={toggleCamera}  className="toggle-btn">{camera === "user" ? "Back Camera" : "front Camera"}</button> */}
//           <button onClick={toggleCamera} className="toggle-btn">
//             {camera === "user" ? (
//               <FontAwesomeIcon icon={faCamera} />
//             ) : (
//               <FontAwesomeIcon icon={faSync} />
//             )}
//           </button>

//           {/* code for download button and copy button  */}

//            {processedVideoUrl && (
//                   <div className="video-link">
//                       <button onClick={copyToClipboard} className="copy-btn"><FontAwesomeIcon icon={faCopy} /> </button>
//                       <button className="download-btn"><a href={processedVideoUrl} download={`processed_video.mp4`} className="download-btn"><FontAwesomeIcon icon={faDownload}/></a></button>
//                   </div>
//             )
//             }

//               {
//                 showMessage && (
//                   <div className="alert alert-success" style={{position:'absolute', top:'40%', width:'350px' , padding:'30px', backgroundColor:'white'}}>
//                       Your video has been processed! You will receive your video soon with AR effects.
//                       <button onClick={() =>{
//                          setShowMessage(false)
//                         navigate('/');}} className="close-btn" style={{ float: 'right', cursor: 'pointer', position:'absolute', top:'0px',right:'0px', backgroundColor:'red' }}>
//                       <FontAwesomeIcon icon={faXmark} />
//       </button>
//                   </div>
//                 )
//               }

//         </div>
//       </div>
//     </>
//   );
// }

// correct code - edited 3rd feb - (captcha + form) not added

// //  // correct code(25 oct) - converted video is display on video preview....
// import { useRef , useState, useEffect } from "react";
// import './Video_recorder.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCopy, faCamera, faSync, faDownload, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { faSquare, faCircle } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from "react-router-dom";
// import ConfirmModal from "../confirmModal/ConfirmModal";
// // import FormModal from "../formModal/FormModal";

// export default function Video_recorder(){
//     const videoRef = useRef(null);
//     const [camera, setCamera] = useState("user");
//     const [videoUpload, setVideoUpload] = useState(null);
//     const [processedVideoUrl, setProcessedVideoUrl] = useState(null);
//     const [loading , setLoading] = useState(false)
//     const [myVideo, setMyVideo] = useState(null);
//     const [isRecording , setIsRecording] = useState(false);
//     const [showConfirmModal, setShowConfirmModal] = useState(false);
//     const [modalMessage, setModalMessage] = useState("");
//     const mediaRecorder = useRef(null)
//     const videoChunks = useRef([])

//     let navigate = useNavigate();

//     const handleBackClick = () => {
//         let message = {
//             title: "",
//             body: ""
//         };
//         if (isRecording) {
//             message.title = "Recording in Progress";
//             message.body = "Recording is in progress. Do you want to keep or discard or start over the recording?";
//         } else if (videoUpload && !processedVideoUrl) {
//             message.title = "Recording Complete";
//             message.body = "Recording complete. Do you want to keep this recording or discard or start over?";
//         } else if (processedVideoUrl) {
//             message.title = "Video Processed";
//             message.body = "Do you want to keep the converted video or discard or start over?";
//         } else {
//             navigate('/'); // No modal needed if none of the above conditions are met
//             return;
//         }
//         setModalMessage(message);
//         setShowConfirmModal(true);
//     };

//     const handleConfirmLeave = () => {
//         if (isRecording || videoUpload) {
//             if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
//                 mediaRecorder.current.stop();
//             }
//             setIsRecording(false);
//             setVideoUpload(null);
//             setProcessedVideoUrl(null);
//         }
//         navigate('/');
//         setShowConfirmModal(false);
//     };

//     const handleCancelLeave = () => {
//         setShowConfirmModal(false);
//     };

//     const handleRestartRecording = () => {
//       console.log('restart function enter')
//       if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
//           mediaRecorder.current.stop();
//           console.log('mediaRecorder :', mediaRecorder)
//       }
//       setIsRecording(false);
//       console.log('recording discarded')
//       setVideoUpload(null);
//       console.log('video upload discarded')
//       setProcessedVideoUrl(null);
//       console.log('processed video discarded')
//       setShowConfirmModal(false);
//       console.log('modal shown')
//       // startRecording();
//       initializeCamera()
//       console.log('new recording started')
//   };

//   const initializeCamera = async () => {
//     try {
//       const stream = await getMediaStream();
//       videoRef.current.srcObject = stream;
//     } catch (error) {
//       console.error('Error accessing the camera:', error);
//     }
//   };

//   useEffect(() => {
//     initializeCamera();
//     return () => {
//       if (videoRef.current && videoRef.current.srcObject) {
//         const tracks = videoRef.current.srcObject.getTracks();
//         tracks.forEach((track) => track.stop());
//       }
//     };
//   }, [camera]);

//   useEffect(() => {
//     const matchMedia = window.matchMedia("(min-width: 768px)");
//     const handleChange = (e) => {
//             // Automatically switch to front camera on big screens
//       setCamera(e.matches ? "user" : camera);
//       if (videoRef.current && videoRef.current.srcObject) {
//         stopMediaStream(videoRef.current.srcObject);
//         startRecording();
//         }
//       };
//         matchMedia.addEventListener("change", handleChange);
//          return () => {
//              matchMedia.removeEventListener("change", handleChange);}
//     }, [camera]);

//     const getMediaStream = async () => {
//       if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
//         console.error("getUserMedia is not supported in this browser.");
//         alert('Error accessing the camera or microphone. Please ensure you have granted the necessary permissions.');
//         throw new Error("getUserMedia not supported");
//       }
//       const constraints = {
//         video: { facingMode: camera },
//         // audio: true,
//         audio: {
//           echoCancellation:true, // Enable echo cancellation
//           noiseSuppression:true, // Enable noise suppression
//           autoGainControl:false,
//       sampleRate: 44100, // Standard sample rate for good quality
//       channelCount: 1,
//         },
//       };
//       return await navigator.mediaDevices.getUserMedia(constraints);
//     };

//     const toggleCamera = async () => {
//       if (isRecording) {
//            stopRecording();
//       }

//       const newCamera = camera === "user" ? "environment" : "user";
//       setCamera(newCamera);

//       if (isRecording) {
//           await new Promise(resolve => setTimeout(resolve, 500)); // Optional: Add delay to ensure media stream is properly initialized
//           startRecording();
//       }
//       console.log('camera :',camera);
//   };

//     const startRecording = () => {
//       setIsRecording(true);
//       const stream = videoRef.current.srcObject;
//       if (!stream) {
//         console.error('No media stream available for recording');
//         // alert('No media stream available for recording');
//         setIsRecording(false);
//         return;
//       }
//       mediaRecorder.current = new MediaRecorder(stream);
//       videoChunks.current = []; // Clear previous data chunks
//       mediaRecorder.current.ondataavailable = (e) => {
//         videoChunks.current.push(e.data);
//       };
//       mediaRecorder.current.start();
//       console.log('Started recording');
//     };

//   const stopRecording = () => {
//     console.log('stop block');
//     console.log(mediaRecorder.current);
//     if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
//       console.log('stop function working');
//       mediaRecorder.current.stop();
//       console.log('stop function');
//       mediaRecorder.current.onstop = () => {
//         const mimeType = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
//         const videoBlob = new Blob(videoChunks.current, {
//           type: MediaRecorder.isTypeSupported(mimeType) ? mimeType : 'video/webm'
//         });
//         setVideoUpload(videoBlob);
//         const videoUrl = URL.createObjectURL(videoBlob);
//         videoRef.current.srcObject = null;
//         videoRef.current.src = videoUrl;
//         videoChunks.current = [];
//         setIsRecording(false);
//         console.log('stopped recording');
//       };
//       stopMediaStream(videoRef.current.srcObject);
//     } else {
//       console.error('Attempted to stop recording when mediaRecorder is not active or not initialized.');
//     }
//   };

//     const stopMediaStream = (stream, callback) => {
//         if (stream) {
//             const tracks = stream.getTracks();
//             tracks.forEach(track => track.stop());
//             console.log("All tracks stopped.");
//             if (callback) {
//                 callback();
//             }
//         }
//     };

//     const uploadVideo = async () => {

//         setLoading(true)

//         const formData = new FormData();
//         formData.append('video', videoUpload, 'upload_video.mp4');

//         try {
//             const response = await fetch('http://localhost:5000/upload_video', {
//                 method: 'POST',
//                 body: formData,
//             });
//             const data = await response.blob();
//             if (!response.ok) throw new Error('Server responded with an error');
//             const videoUrl = URL.createObjectURL(data);
//            setProcessedVideoUrl(videoUrl);
//             videoRef.current.src = videoUrl; // Update the video source to show the processed video
//             // alert('Video uploaded successfully!');
//         } catch (error) {
//             console.error('Error uploading video:', error);
//             alert('Failed to upload video.');
//         }finally {
//             setLoading(false);
//         }
//     };

//     console.log("processedVideoUrl", processedVideoUrl);

//     const copyToClipboard = () => {
//         navigator.clipboard.writeText(processedVideoUrl)
//             .then(() => console('Link copied to clipboard!'))
//             .catch(err => console.error('Could not copy text: ', err));
//     };

//     return(
//         <>
//         <div className="rec">
//        {/* <h2>Create a special message!</h2> */}

//         {myVideo ? (
//             <video width="600" controls>
//                 <source src={myVideo} type="video/mp4" />
//             </video>
//         ) : (
//             <video
//                 ref={videoRef}
//                 //controls
//                 autoPlay
//                 playsInline
//                 loop
//                 style={{ width: '100%' }}
//                 className="video-mirrored"
//             ></video>
//          )}

//       <div className="rec_btn">

//  <ConfirmModal
//                 isOpen={showConfirmModal}
//                 onConfirm={handleConfirmLeave}
//                 onCancel={handleCancelLeave}
//                 message={modalMessage}
//                 onRestart={handleRestartRecording}
//             />

// <button onClick={handleBackClick} className="back-btn">
//                     Back
//                 </button>

// {!isRecording ? (
//                     <button onClick={startRecording} className="recording-btn"><FontAwesomeIcon icon={faCircle}/></button>
//                 ) : (
//                     <button onClick={stopRecording} className="recording-btn active-btn"><FontAwesomeIcon icon={faSquare}/></button>
//                 )}

//       {!processedVideoUrl  && videoUpload && (
//                          <button onClick={uploadVideo} disabled={loading} className="upload-btn">
//                              {loading ? "Uploading..." : "Next"}
//                          </button>
//                     )}
//       {/* <button onClick={toggleCamera}  className="toggle-btn">{camera === "user" ? "Back Camera" : "front Camera"}</button> */}
//       <button onClick={toggleCamera}  className="toggle-btn">{camera === "user" ? <FontAwesomeIcon icon={faCamera} /> : <FontAwesomeIcon icon={faSync} /> }</button>

//       {processedVideoUrl && (
//             <div className="video-link">
//                 <button onClick={copyToClipboard} className="copy-btn"><FontAwesomeIcon icon={faCopy} /> </button>
//                 <button className="download-btn"><a href={processedVideoUrl} download={`processed_video.mp4`} className="download-btn"><FontAwesomeIcon icon={faDownload}/></a></button>
//             </div>
//         )}
//       </div>

//       </div>
//         </>
//     )
// }
