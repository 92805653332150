// import { Col, Container, Row } from 'react-bootstrap'
// import './Occasion.css'
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import { useEffect, useState } from 'react';
// import useVisible from '../visibility/UseVisible';


// const cardsData = [
//     { id: 1, title: 'Birthdays', message: 'Add an extra layer of surprise to your birthday wishes!' },
//     { id: 2, title: 'Anniversaries', message: 'Celebrate love by making your presence felt, even when apart.' },
//     { id: 3, title: 'Congratulations', message: 'Say "Congrats!" in a way that truly stands out.' },
//     { id: 4, title: 'Holidays', message: 'Send seasonal greetings that bring a personal touch.' },
//     { id: 5, title: 'Just Because', message: "Brighten someone’s day with a heartfelt message." },
//     { id: 6, title: 'Festival', message: "CEO/Directors can wish all their company employees (lets say Christmas)with more engaging experience." },
//     { id: 7, title: 'Announcement', message: 'Company team leads or higher management can announce any employees promotion to make him feel special and make the moment memorable.' },
//   ];

// export default function Occasion(){
//     const [ ref, isVisible] = useVisible();

//     const [activeSlide, setActiveSlide] = useState(2); 
//     const[ hasBecomeVisible, setHasBecomeVisible] = useState(false)

//     useEffect(()=>{
//         if(isVisible && !hasBecomeVisible){
//             setHasBecomeVisible(true)
//         }
//     },[isVisible, hasBecomeVisible])

//     const settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         autoplay: true,
//         autoplaySpeed: 2000,
//         slidesToShow: 5,
//         centerMode: true,
//         beforeChange: (current, next) => setActiveSlide(next),
//         slidesToScroll: 1,
//         responsive: [
//             {
//                 breakpoint: 1025,
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 1,
//                     infinite: true,
//                     dots: true
//                 }
//             },
//             {
//                 breakpoint: 540,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 1,
//                     initialSlide: 1
//                 }
//             },{
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                     initialSlide: 1
//                 }
//             }
//         ]
//     };
//     return(
//         <>
//         <div className='occasion-main' ref={ref}>
        
//         <Container className={`occasion-container ${hasBecomeVisible ? 'work-visible' : 'work-hidden'}`}>
//             <Row>
//                 <Col>
//                     <div className={`occasion-heading ${isVisible ?'slide-up' : ''}`}>
//                         <h1>Send the Perfect Greeting for Any Occasion </h1>
//                     </div>
//                 </Col>
//             </Row>

//             <Row>
//                 <Col>
//                     <div className='occasion-parts'>
//                         <Row>
//                             <Col >
//                             <Slider {...settings}>
//                 {cardsData.map(card => (
//                     <div key={card.id} className="occasion-item">
                    
//                         <div className="occasion-content">
//                             <h2>{card.title}</h2>
//                             <p>{card.message}</p>
//                         </div>
//                     </div>
//                 ))}
//             </Slider>
//                             </Col>
//                         </Row>
//                     </div>
//                 </Col>
//             </Row>
//         </Container>
             
//         </div>
//         </>
//     )
// }





import { Col, Container, Row } from 'react-bootstrap'
import './Occasion.css'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from 'react';
import useVisible from '../visibility/UseVisible';
import birthday from '../images/birthday.gif';
import happy from '../images/happy.jpg'
import travel from '../images/travel.jpg';
import anniversary from '../images/anniversary.jpg';
import congratulation from '../images/congratulation.jpg';
import festival from '../images/festival.jpg'
import announcement from '../images/announcement.jpg';

const cardsData = [
    { id: 1, title: 'Birthdays', message: 'Add an extra layer of surprise to your birthday wishes!',image : birthday },
    { id: 2, title: 'Anniversaries', message: 'Celebrate love by making your presence felt, even when apart.',image : anniversary },
    { id: 3, title: 'Congratulations', message: 'Say "Congrats!" in a way that truly stands out.',image : congratulation },
    { id: 4, title: 'Holidays', message: 'Send seasonal greetings that bring a personal touch.',image : travel },
    { id: 5, title: 'Just Because', message: "Brighten someone’s day with a heartfelt message.",image : happy },
    { id: 6, title: 'Festival', message: "CEOs/Directors: Send Engaging Christmas Wishes to Employees!",image : festival },
    { id: 7, title: 'Announcement', message: 'Team Leads: Announce Promotions and Make Moments Memorable!',image : announcement },
  ];

export default function Occasion(){
    const [ ref, isVisible] = useVisible();

    const [activeSlide, setActiveSlide] = useState(2); 
    const[ hasBecomeVisible, setHasBecomeVisible] = useState(false)

    useEffect(()=>{
        if(isVisible && !hasBecomeVisible){
            setHasBecomeVisible(true)
        }
    },[isVisible, hasBecomeVisible])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        centerMode: true,
        beforeChange: (current, next) => setActiveSlide(next),
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 540,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },{
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };
    return(
        <>
        <div className='occasion-main' ref={ref}>
        
        <Container className={`occasion-container ${hasBecomeVisible ? 'work-visible' : 'work-hidden'}`}>
            <Row>
                <Col>
                    <div className={`occasion-heading ${isVisible ?'slide-up' : ''}`}>
                        <h1>Send the Perfect Greeting for Any Occasion </h1>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className='occasion-parts'>
                        <Row>
                            <Col >
                            <Slider {...settings}>
                {cardsData.map(card => (
                    <div key={card.id} className="occasion-item">
                    
                        <div className="occasion-content">
                            <h2>{card.title}</h2>
                            <p>{card.message}</p>
                            <img src={card.image} alt={card.title} style={{ width: '100px', height: '100px', margin:'auto' }}/>
                        </div>
                    </div>
                ))}
            </Slider>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
             
        </div>
        </>
    )
}





