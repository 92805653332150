import { Container, Row, Col } from "react-bootstrap";
import "./Pricing.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import useVisible from "../visibility/UseVisible";
import { useEffect, useState } from "react";
import ContactModal from "../../contactModal/ContactModal";
import { useNavigate } from "react-router-dom";

const plans = [
  {
    id: 1,
    plan_name: "Basic Plan",
    price: "9.99",
    msg1: "10 Second AR Video",
    msg2: "HD quality",
    msg3: "14-day link availability",
    msg4: "Basic support",
    msg5: "No add on features",
  },
  {
    id: 2,
    plan_name: "Premium Plan",
    price: "14.99",
    msg1: "30 Second AR Video",
    msg2: "HD quality",
    msg3: "30-day link availability",
    msg4: "Priority support",
    msg5: "No add on features",
  },
  {
    id: 3,
    plan_name: "Custom Pricing",
    price: "",
    msg1: "Customized video length",
    msg2: "HD quality",
    msg3: "customized day link availability",
    msg4: "Priority support",
    msg5: "customized add on features",
  },
];

export default function Pricing() {
  const [ref, isVisible] = useVisible();
  const [hasBecomeVisible, setHasBecomeVisible] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [contactData, setContactData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isVisible && !hasBecomeVisible) {
      setHasBecomeVisible(true);
    }
  }, [isVisible, hasBecomeVisible]);

  const handleButtonClick = (item) => {
    if (item.plan_name === "Custom Pricing") {
      setShowContactForm(true);
    } else {
      console.log("Buy");
      navigate('/buy')
    }
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    console.log("Contact Data:", contactData);

    try {
      const response = await fetch(`http://localhost:4000/contactUs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactData),
      });

      if (response.ok) {
        console.log("Contact saved successful");
        setShowContactForm(false);
        setFormSubmitted(true);
      } else {
        const errorData = await response.json();
        console.error("Contact data failed:", errorData.message);
      }
    } catch (error) {
      console.error("Network error:", error.message);
    }
  };

  const handleContactChange = (e) => {
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <div className="pricing-main" ref={ref}>
        <Container
          className={`pricing-container ${
            hasBecomeVisible ? "work-visible" : "work-hidden"
          }`}
        >
          <Row>
            <Col>
              <div className={`pricing-heading ${isVisible ? "slide-up" : ""}`}>
                <h1>Choose the Plan that’s Right for You</h1>
              </div>
            </Col>
          </Row>

          <Row>
            {plans.map((item, index) => (
              <Col
                key={item.id}
                className={`pricing-plans ${isVisible ? "slide-up" : ""}`}
              >
                <div className="plan-structure">
                  <h2>{item.plan_name}</h2>
                  <h4>
                    ${item.price} <span>per month</span>
                  </h4>
                  <hr />
                  {item.msg1 && (
                    <p>
                      <FontAwesomeIcon icon={faCaretRight} /> {item.msg1}
                    </p>
                  )}
                  {item.msg2 && (
                    <p>
                      <FontAwesomeIcon icon={faCaretRight} /> {item.msg2}
                    </p>
                  )}
                  {item.msg3 && (
                    <p>
                      <FontAwesomeIcon icon={faCaretRight} /> {item.msg3}
                    </p>
                  )}
                  {item.msg4 && (
                    <p>
                      <FontAwesomeIcon icon={faCaretRight} /> {item.msg4}
                    </p>
                  )}
                  {item.msg5 && (
                    <p>
                      <FontAwesomeIcon icon={faCaretRight} /> {item.msg5}
                    </p>
                  )}

                  <button
                    className="pricing-btn"
                    onClick={() => handleButtonClick(item)}
                  >
                    {item.plan_name === "Custom Pricing"
                      ? "Contact Us"
                      : "Buy Now"}
                  </button>

                  <ContactModal
                    show={showContactForm}
                    onHide={() => setShowContactForm(false)}
                    onSubmit={handleContactSubmit}
                    onChange={handleContactChange}
                    formData={contactData}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}
