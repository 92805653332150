// import { Col, Container, Row } from "react-bootstrap";
// import "./Works.css";
// import { useNavigate } from "react-router-dom";
// import useVisible from "../visibility/UseVisible";
// import { useEffect, useState } from "react";
// import gif1 from "../images/birthday.gif";
// // import gif2 from "../images/Xs.gif";
// // import gif3 from "../images/XsRecode.mp4";
// import record_gif from '../images/record.mp4'

// export default function Works() {
//   let navigate = useNavigate();

//   const [ref, isVisible] = useVisible({
//     threshold: 0.1,
//   });

//   const [hasBecomeVisible, setHasBecomeVisible] = useState(false);

//   useEffect(() => {
//     if (isVisible && !hasBecomeVisible) {
//       setHasBecomeVisible(true);
//     }
//   }, [isVisible, hasBecomeVisible]);

//   const navigateToUploads = () => {
//     navigate("/uploads");
//   };

//   const msg = [
//     {
//       id: 1,
//       title: "Record Your Video",
//       message:
//         "Create a special message. Whether it's a birthday wish, a congratulatory note, or any other greeting, simply record yourself.",
//       gif: record_gif,
//     },

//     {
//       id: 2,
//       title: "Share a Magic Link",
//       message: "Get a unique link to share with your friends or loved ones.",
//       gif: record_gif,
//     },
//   ];
//   return (
//     <>
//       <div className="work-main" ref={ref}>
//         <Container className="work">
//           <div
//             className={`work-container ${
//               hasBecomeVisible ? "work-visible" : "work-hidden"
//             }`}
//           >
//             <Row>
//               <Col>
//                 {/* <div className={`work-title ${isVisible ? 'fade-in' : ''}`}> */}
//                 <div className={`work-title ${isVisible ? "slide-up" : ""}`}>
//                   <h1>It’s Simple, Fun, and Magical!</h1>
//                 </div>
//               </Col>
//             </Row>

//             <Row className="work-row">
//               {msg.map((item) => (
//                 <Col
//                   key={item.id}
//                   xs={11}
//                   md={6}
//                   lg={5}
//                   className={`work-col ${isVisible ? "slide-up" : ""}`}
//                   style={{ backgroundImage: `url(${item.gif})` }}
//                 >
//                   <div className="works-step">
//                     <h2>0{item.id}</h2>
//                     <hr></hr>
//                     <h3>{item.title}</h3>
//                     <p>{item.message}</p>
//                   </div>
//                 </Col>
//               ))}
//             </Row>

//             <Row>
//               <Col>
//                 <button
//                   className={`work-btn ${isVisible ? "slide-up" : ""}`}
//                   onClick={navigateToUploads}
//                 >
//                   Start Now
//                 </button>
//               </Col>
//             </Row>
//           </div>
//         </Container>
//       </div>
//     </>
//   );
// }





















import { Col, Container, Row } from "react-bootstrap";
import "./Works.css";
import { useNavigate } from "react-router-dom";
import useVisible from "../visibility/UseVisible";
import { useEffect, useState } from "react";
import gif1 from "../images/birthday.gif";
// import gif2 from "../images/Xs.gif";
// import gif3 from "../images/XsRecode.mp4";
import record from '../images/record.mp4';
import link from '../images/link.mp4';

export default function Works() {
  let navigate = useNavigate();

  const [ref, isVisible] = useVisible({
    threshold: 0.1,
  });

  const [hasBecomeVisible, setHasBecomeVisible] = useState(false);

  useEffect(() => {
    if (isVisible && !hasBecomeVisible) {
      setHasBecomeVisible(true);
    }
  }, [isVisible, hasBecomeVisible]);

  const navigateToUploads = () => {
    navigate("/uploads");
  };

  const msg = [
    {
      id: 1,
      title: "Record Your Video",
      message:
        "Create a special message, simply record yourself.",
        video: record,
    },

    {
      id: 2,
      title: "Share a Magic Link",
      message: "Get a unique link to share with your friends or loved ones.",
      video: link,
    },
  ];
  return (
    <>
      <div className="work-main" ref={ref}>
        <Container className="work">
          <div
            className={`work-container ${
              hasBecomeVisible ? "work-visible" : "work-hidden"
            }`}
          >
            <Row>
              <Col>
                {/* <div className={`work-title ${isVisible ? 'fade-in' : ''}`}> */}
                <div className={`work-title ${isVisible ? "slide-up" : ""}`}>
                  <h1>It’s Simple, Fun, and Magical!</h1>
                </div>
              </Col>
            </Row>

            <Row className="work-row">
              {msg.map((item) => (
                <Col
                  key={item.id}
                  xs={11}
                  md={6}
                  lg={5}
                  className={`work-col ${isVisible ? "slide-up" : ""}`}
                  style={{ backgroundImage: `url(${item.gif})` , padding:'0px'}}
                >
                  <div className="video-container">
                  <video autoPlay loop muted playsInline className="background-video">
                    <source src={item.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div className="works-step">
                    <h2 className={`step-title ${isVisible ? "pulse " : ""}`}>Step {item.id}</h2>
                    <hr />
                    <h3>{item.title}</h3>
                    <p>{item.message}</p>
                  </div>
                </div>
                </Col>
              ))}
            </Row>

            <Row>
              <Col>
                <button
                  className={`work-btn ${isVisible ? "slide-up" : ""}`}
                  onClick={navigateToUploads}
                >
                  Start Now
                </button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}
