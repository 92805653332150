import { Col, Container, Row } from 'react-bootstrap'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebookF , faTwitter, faInstagram, faLinkedinIn, faYoutube } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';


export default function Footer(){

    let navigate = useNavigate();
     
    const navigateToUploads = ()=>{
       navigate('/uploads')
    }
    return(
        <>
        <div className='footer-section'>
            <Container fluid className='footer-main'>
                <Row style={{width:'90%', margin:'auto'}}>
                    <Col xs={12} md={6}>
                        <div className='footer-left'>
                           <h1>Ready to Make Your Greetings Stand Out?</h1>
                           <button className='footer-btn' onClick={navigateToUploads}>Start Now</button>
                        </div>

                        <div className='social-icons'>
                        {/* <FontAwesomeIcon icon={faFacebookF } /> */}
                        <a href='https://www.facebook.com/share/15WBeT6nQq/?mibextid=wwXIfr' target='_blank'><FontAwesomeIcon icon={faFacebookF} className='icons' style={{backgroundColor:'blue'}}/></a>
                        {/* <a href='https://x.com/messagear34?s=21&t=DvoWCyQ6Hk0ylx9z5FqVYA' target='_blank'><FontAwesomeIcon icon={faTwitter} className='icons'/></a> */}
                        <a href='https://www.instagram.com/messageardotcom?igsh=MXJyaWZ5Z241eDNiZA%3D%3D&utm_source=qr' target='_blank'><FontAwesomeIcon icon={faInstagram} className='icons' style={{backgroundColor:'red'}}/></a>
                        <a href='https://www.tiktok.com/@message.ar?_t=ZM-8tgUQgj9hy9&_r=1' target='_blank'><FontAwesomeIcon icon={faTiktok} className='icons' style={{backgroundColor:'black'}}/></a>
                        </div>

                        
                    </Col>
                    <Col xs={12} md={6} style={{textAlign:'center'}}>
                        <div className='footer-right'>
                        <h1>Contact Us</h1>
                        </div>

                        <div className='contact'>
                            <p><FontAwesomeIcon icon={faEnvelope} style={{fontSize:'25px'}}/>  <span>contact@messagear.com</span></p>
                            {/* <p><FontAwesomeIcon icon={faLocationDot} />  <span>123 main street, India</span></p> */}
                        </div>
                    </Col>
                </Row>

<Row>
    <Col className='text-center'>
    <p style={{color:'white'}}>@ messagear {new Date().getFullYear()} | Powered by MessageAR</p>
    </Col>
</Row>
               
            </Container>
            </div>
        </>
    )
}