import { Col, Container, Row } from 'react-bootstrap'
import './Moment.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo , faWandMagicSparkles , faRocket, faSatellite , faVrCardboard } from '@fortawesome/free-solid-svg-icons';
import decorate1 from '../images/decorate1.png'
import { useState, useEffect } from 'react';
import useVisible from '../visibility/UseVisible';


export default function Moment(){
    // const [offset, setOffset] = useState(0);
    const [ref, isVisible] = useVisible();

    const [hasBecomeVisible, setHasBecomeVisible] = useState(false);

    useEffect(()=>{
        if(isVisible && !hasBecomeVisible){
            setHasBecomeVisible(true);
        }
    },[isVisible, hasBecomeVisible])

    // useEffect(() => {
    //     const handleScroll = () => setOffset(window.pageYOffset);
    //     window.addEventListener('scroll', handleScroll);

    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, []);

    const moments=[
        {id : 1, icon:faVideo  , title : 'Personal Touch' , message : "Instead of sending a text or generic video, our platform allows your loved ones to feel like you're right there with them, no matter the distance."},
        {id : 2, icon:faVrCardboard , title : 'Augmented Reality Experience' , message : "Take greetings to the next level with immersive augmented reality. The receiver can view your video in their surroundings for a more engaging experience."},
        // {id : 3, icon:faWandMagicSparkles , title : 'Effortless Background Removal' , message : "No need to set up a green screen! Our smart technology does the work for you, making your video look seamless in any environment."},
        {id : 4, icon:faRocket  , title : 'Fast & Easy' , message : "Record, share, and surprise your loved ones."}
    ]
    return(
        <>
        <Container fluid className='moment-container' ref={ref}>
            <div className={`moment-main ${hasBecomeVisible ? 'work-visible' : 'work-hidden'}`}>
            <Row>
                <Col>
                <div className={`moment-heading ${isVisible ? 'slide-up' : ''}`}>
                    <h1> Make Every Moment More Memorable</h1>
                </div>

                {/* <span className='decorating-item1'>
                    <img src={decorate1} alt="decorating item1" />
                </span> */}
                </Col>
            </Row>

            <Row className='d-flex justify-content-around'>
                {moments.map((item)=>(
                    <Col key={item.id} xs={12} md={6} lg={3} className={`moment-col ${isVisible ? 'slide-up' : ''}`}>
                    <div className='moment-msg'>
                        <Row>
                            <Col xs={2}><span><FontAwesomeIcon icon={item.icon} /></span></Col>
                            <Col xs={10}><h3>{item.title}</h3></Col>
                        </Row>
                    {/* <h3><span><FontAwesomeIcon icon={item.icon} /></span> {item.title}</h3> */}
                        <p>{item.message}</p>
                    </div>
                    </Col>
                ))}
            </Row>
            </div>
            
        </Container>
        </>
    )
}