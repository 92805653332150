import './Testimonials.css'
import { Col, Container, Row } from 'react-bootstrap'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faQuoteRight } from '@fortawesome/free-solid-svg-icons'; 
// import pattern from '../images/pattern.webp'
import person1 from '../images/person1.jpeg'
import person2 from '../images/person2.webp'
import person3 from '../images/person3.webp'
import person4 from '../images/person4.jpeg'
import useVisible from '../visibility/UseVisible';
import Slider from 'react-slick';
import { useEffect, useState } from 'react';

const testimonialsData = [
    {
      id: 1,
      image:person1,
      stars: 5,
      message: "It felt like my best friend was standing right in front of me, wishing me happy birthday! Such a unique experience. I loved it!",
      user: "Emma Roberts"
    },
    {
      id: 2,
      image: person2,
      stars: 4,
      message: "Sending video greetings like this made my anniversary wish so much more special for my partner.",
      user: "John Doe "
    },
    {
      id: 3,
      image: person3,
      stars: 5,
      message: "Super easy to use, and the AR effect was mind-blowing for my family!",
      user: "Alice Johnson"
    },
    {
      id: 4,
      image: person4,
      stars: 4,
      message: "I sent a MessageAR video to my mom on Mother's Day, and she was in tears! Seeing me in her own space made it feel so real. This is the future of video greetings!",
      user: "Mike Brown"
    }
  ];

export default function Testimonials(){

    const[ref, isVisible] = useVisible();
    const[hasBecomeVisible, setHasBecomeVisible] = useState(false);

    useEffect(()=>{
        if(isVisible && !hasBecomeVisible){
            setHasBecomeVisible(true)
        }
    },[isVisible, hasBecomeVisible])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        draggable: true,
        arrows:true,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }]
      };

    return(
        <>
            {/* <Container fluid className='testimonial-container' style={{backgroundImage:`url(${pattern})`,backgroundSize: 'cover', backgroundPosition: 'center'}}>  */}
            <Container fluid className='testimonial-container' ref={ref}> 
                <div className={`test ${hasBecomeVisible ? 'work-visible':'work-hidden'}`}>
                <Row>
                    <Col>
                    <div className={`testimonials-main ${isVisible ? 'slide-up' :''}`}>
                        <h1>What Our Users Are Saying</h1>
                    </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className='testimonials'>
                            <Slider {...settings}>
                                {testimonialsData.map(testimonial => (
                                <div key={testimonial.id} className="testimonial-item">
                                    <div className="testimonial-image">
                                        <img src={testimonial.image} alt={testimonial.user} />
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="testimonial-stars">
                                            {'★'.repeat(testimonial.stars)}
                                            <span>
                                            <FontAwesomeIcon icon={faQuoteRight} size="2x" />
                                            </span>
                                        </div>
                                        <p className="testimonial-message">{testimonial.message}</p>
                                        <p className="testimonial-user">{testimonial.user}</p>
                                    </div>
                                </div>
                                ))}        
                            </Slider>      
                        </div>                    
                    </Col>
                </Row>
                </div>
            </Container>
        </>
    )
}